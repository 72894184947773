import { useEffect, useRef, useState } from "react";
import { Box, Button, IconButton, TextField, Typography,FormControl,FormGroup,FormControlLabel,Checkbox,Link } from "@mui/material";
import { AccountCircle, CloudDone, Delete, Email, InsertDriveFile, Laptop, Phone, Work } from "@mui/icons-material";
import { fileToBase64 } from "../../services/serviceslocal";


export default function FormSendResume({odQ1,odQ2,odQ3,numberJob, handlerChange, contactInformation, setContactInformation, emptyResumeFile, setEmptyResumeFile}) {
        
    const [name, setName] = useState(contactInformation?contactInformation.name:"")
    const [email, setEmail] = useState(contactInformation?contactInformation.email:"")
    const [phone, setPhone] = useState(contactInformation?contactInformation.phone:"")
    const [profession, setProfession] = useState(contactInformation?contactInformation.profession:"")                

    const refInputFile = useRef()
    const [fileInput, setFileInput] = useState(false);
    const [answers, setAnswers] = useState({
        answer1: contactInformation ? contactInformation.answer : false,
        answer2: contactInformation ? contactInformation.answer2 : false,
        answer3: contactInformation ? contactInformation.answer3 : false,
        authorizedToWork: contactInformation ? contactInformation.authorizedToWork : false,
        visaSponsorship: contactInformation ? contactInformation.visaSponsorship : false,
        privacyPolicy: contactInformation ? contactInformation.privacyPolicy : true,
    });

    const [numericInput, setNumericInput] = useState(contactInformation ? contactInformation.numericInput : "");
    

    useEffect(() => {
        setContactInformation({ name, email, phone, profession, fileInput, ...answers, numericInput });
    }, [name, email, phone, profession, fileInput, answers, numericInput]);


    const getFile = ({target}) => {
        if(target.files.length > 0 ){
            setEmptyResumeFile(false)
            let file = target.files[0] 
            fileToBase64(file).then(base64 => {setFileInput({"file":file, "base64":base64})})  
        }
    }

    const handleCheckboxChange = (key) => ({ target }) => {
        setAnswers((prev) => ({ ...prev, [key]: target.checked }));
    }

    const handleNumericInputChange = ({ target }) => {
        setNumericInput(target.value);
    };

    const showQ1 = odQ1!=null && odQ1!='' && numberJob!='JN -062019-3978' && numberJob!='JN -032023-5195';
    const showQ2 = odQ2!=null && odQ2!='' && numberJob!='JN -062019-3978' && numberJob!='JN -032023-5195';
    const showQ3 = odQ3!=null && odQ3!='' && numberJob!='JN -062019-3978' && numberJob!='JN -032023-5195';
    

    return (
        <Box className="flex col w100">
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField          
                    className="w100"
                    label="Full name"
                    size="small"
                    value={name}
                    onChange={({currentTarget}) => {handlerChange(setName, currentTarget)}}
                    id="nameInput"
                />
            </Box>
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <Email sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                    sx={{ p: 0 }}
                    className="w100"
                    label="Email address"
                    size="small"
                    variant="outlined"
                    value={email}
                    onChange={({currentTarget}) => {handlerChange(setEmail, currentTarget)}}
                    id="emailInput"
                />
            </Box>
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <Phone sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                    className="w100"
                    label="Phone Number"
                    size="small"
                    variant="outlined"
                    value={phone}
                    onChange={({currentTarget}) => {handlerChange(setPhone, currentTarget)}}
                    id="phoneInput"
                />
            </Box>
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <Work sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                    className="w100"
                    label="Profession"
                    size="small"
                    variant="outlined"
                    value={profession}
                    onChange={({currentTarget}) => {handlerChange(setProfession, currentTarget)}}
                    id="professionInput"
                />
            </Box>
            <Box className="flex row" sx={{ alignItems: "center", my: 1}}>
                 <InsertDriveFile sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                 <Box className={`flex col center containerFileResume w100 ${fileInput?"containerFileResumeOn":""} ${emptyResumeFile?"emptyResumeFile":""}`}>
                    {fileInput?
                        <>
                            <IconButton>
                                <CloudDone color="primary" fontSize="large"/>
                            </IconButton>
                            <Typography className="tcenter" variant="body2" color="dark">
                                {fileInput.file.name}
                            </Typography>
                            
                        </>
                    :
                        <>
                            <Typography 
                                className="tcenter" 
                                variant="body2" 
                                color={emptyResumeFile?"primary":"dark"}
                            >
                                Drop Resume here or
                            </Typography>
                            {/* <Typography 
                                className="tcenter" 
                                variant="body2" 
                                color={emptyResumeFile?"primary":"dark"}
                            >
                                or
                            </Typography> */}

                            <Button startIcon={<Laptop/>} variant="outlined" size="small" sx={{borderRadius:"50px", p:"6px 22px"}} >
                                Browse File
                            </Button>
                        </>
                    }
                    <input 
                        ref={refInputFile} 
                        type="file" 
                        className="inputFileResume"
                        onChange={getFile}
                        accept=".doc,.docx,application/msword,image/*,.pdf,video/*"
                        id="fileInput"                           
                    />   
                    {fileInput && 
                        <Box className="iconClose">
                            <IconButton onClick={()=> {setFileInput()}}>
                                <Delete fontSize="small"/>
                            </IconButton>
                        </Box>
                    }      
                </Box>
            </Box>

            {/* <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <Work sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                    className="w100"
                    label={odQ1}
                    size="small"
                    variant="outlined"
                    value={profession}
                    onChange={({currentTarget}) => {handlerChange(setProfession, currentTarget)}}
                    id="professionInput"
                />
            </Box> */}

            {(showQ1)? (
                <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                    <Box className="w100">
                        <Typography variant="body1" gutterBottom>
                            {odQ1}
                        </Typography>
                        <FormControl fullWidth>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={answers.answer1}
                                            onChange={handleCheckboxChange("answer1")}
                                        />
                                    }
                                    label="Yes"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                </Box>
            ) : null}


            {(showQ2) ? (
                <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                    <Box className="w100">
                        <Typography variant="body1" gutterBottom>
                            {odQ2}
                        </Typography>
                        <FormControl fullWidth>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={answers.answer2}
                                            onChange={handleCheckboxChange("answer2")}
                                        />
                                    }
                                    label="Yes"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                </Box>
            ) : null}

            {(showQ3) ? (
                <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                    <Box className="w100">
                        <Typography variant="body1" gutterBottom>
                            {odQ3}
                        </Typography>
                        <FormControl fullWidth>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={answers.answer3}
                                            onChange={handleCheckboxChange("answer3")}
                                        />
                                    }
                                    label="Yes"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                </Box>
            ) : null}
            

            {/* <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                
                <Box className="w100">
                    <Typography variant="body1" gutterBottom>
                        {odQ2}
                    </Typography>
                    <FormControl fullWidth>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={answers.answer2}
                                        onChange={handleCheckboxChange("answer2")}
                                    />
                                }
                                label="Yes"
                            />
                            
                        </FormGroup>
                    </FormControl>
                </Box>
            </Box>

            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                
                <Box className="w100">
                    <Typography variant="body1" gutterBottom>
                        {odQ3}
                    </Typography>
                    <FormControl fullWidth>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={answers.answer3}
                                        onChange={handleCheckboxChange("answer3")}
                                    />
                                }
                                label="Yes"
                            />
                            
                        </FormGroup>
                    </FormControl>
                </Box>
            </Box> */}


            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <Box className="w100">
                    <Typography variant="body1" gutterBottom>
                        Are you currently legally authorized to work in the country where this job is located?
                    </Typography>
                    <FormControl fullWidth>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={answers.authorizedToWork}
                                        onChange={handleCheckboxChange("authorizedToWork")}
                                    />
                                }
                                label="Yes"
                            />
                        </FormGroup>
                    </FormControl>
                </Box>
            </Box>

            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <Box className="w100">
                    <Typography variant="body1" gutterBottom>
                        Do you now or in the future require visa sponsorship for employment to work in this country legally?
                    </Typography>
                    <FormControl fullWidth>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={answers.visaSponsorship}
                                        onChange={handleCheckboxChange("visaSponsorship")}
                                    />
                                }
                                label="Yes"
                            />
                        </FormGroup>
                    </FormControl>
                </Box>
            </Box>
            
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <TextField
                    className="w100"
                    label="What would be your expected base anual salary?"
                    type="number"
                    size="small"
                    variant="outlined"
                    value={numericInput}
                    onChange={handleNumericInputChange}
                    id="numericInput"
                />
            </Box>               

            <FormControlLabel
                control={
                    <Checkbox
                        checked={answers.privacyPolicy}
                        onChange={handleCheckboxChange("privacyPolicy")}
                    />
                }
                label={
                    <Typography variant="body1">
                        Yes, I agree to the{" "}
                        <Link href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                            Terms and Conditions
                        </Link>
                    </Typography>
                }
            />     
            
        </Box>  
     );
}
